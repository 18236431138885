<template>
  <div>
    <v-card outlined :elevation="1" class="mb-2"
      :class="{'yellow lighten-4': cliente.visita && cliente.visita.cod_visita_tipo > 1, 'details-visit': cliente.visita}"
      v-ripple="{ center: true }">
      <v-card-text class="py-5 d-flex flex-column" @click="viewDetails">
        <div class="mb-2 d-flex justify-space-between align-center">
          <span class="blue-grey--text mr-2">
            <v-icon color="icono" size="20" class="mt-n1 mr-1">mdi-account-tie</v-icon>
            <span class="subtitle black--text font-weight-bold" v-text="cliente.co_cli" />
          </span>
          <v-chip label :color="estadoVisita.color" class="py-1" small>
            <span class="font-weight-medium">{{estadoVisita.text}}</span>
          </v-chip>
        </div>
        <div>
          <span class="subtitle black--text" v-text="cliente.cli_des" />
        </div>
        <div class="pt-3 d-flex justify-space-between">
          <div>
            <span v-if="cliente.frecuencia && cliente.frecuencia !== null" class="blue-grey--text mr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="blue-grey lighten-1 " size="19" class="mt-n1" v-bind="attrs"
                    v-on="on">mdi-replay</v-icon>
                </template>
                <span>Frecuencia: {{cliente.frecuencia}}</span>
              </v-tooltip>
              <span class="font-weight-medium text-uppercase">
                {{cliente.frecuencia | truncFrecuencia}}
              </span>
            </span>
            <span v-if="cliente.visita" class="blue-grey--text mr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :color="cliente.pedido === true ? 'blue-grey lighten-1' : 'blue-grey lighten-4' " size="19"
                    class="mt-n1" v-bind="attrs" v-on="on"
                    v-text="cliente.pedido === true ? 'mdi-text-box-check-outline' : 'mdi-text-box-remove-outline'" />
                </template>
                <span v-text="cliente.pedido === true ? 'Pedidos Registrados' : 'Sin Pedidos'" />
              </v-tooltip>
            </span>
            <span v-if="cliente.cobro !== null" class="blue-grey--text mr-2" style="position: relative;">
              <div v-if="!cliente.cobro" class="point-sale-remove"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :color="cliente.cobro === true ? 'blue-grey lighten-1' : 'blue-grey lighten-4' " size="19"
                    class="mt-n1" v-bind="attrs" v-on="on" v-text="'mdi-point-of-sale'" />
                </template>
                <span v-text="cliente.cobro === true ? 'Cobro Registrado' : 'Sin Cobros'" />
              </v-tooltip>
            </span>
            <span class="blue-grey--text mr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="cliente.lat !== null && cliente.lng !== null ? 'blue-grey lighten-1' : 'blue-grey lighten-4' "
                    size="19" class="mt-n1" v-bind="attrs" v-on="on"
                    v-text="cliente.lat !== null && cliente.lng !== null ? 'mdi-map-marker-radius-outline' : 'mdi-map-marker-off-outline'" />
                </template>
                <span
                  v-text="cliente.lat !== null && cliente.lng !== null ? 'Cliente Geolocalizado' : 'Cliente sin Geolocalización'" />
              </v-tooltip>
            </span>
            <template v-if="futuro">
              <v-chip class="mr-1" label x-small color="icono" dark style="padding: 0 10px !important"
                v-if="cliente.lunes=== '1'">
                LU
              </v-chip>
              <v-chip class="mr-1" label x-small color="purple" dark style="padding: 0 10px !important"
                v-if="cliente.martes === '1'">
                MA
              </v-chip>
              <v-chip class="mr-1" label x-small color="info" dark style="padding: 0 10px !important"
                v-if="cliente.miercoles === '1'">
                MI
              </v-chip>
              <v-chip class="mr-1" label x-small color="orange" dark style="padding: 0 10px !important"
                v-if="cliente.jueves === '1'">
                JU
              </v-chip>
              <v-chip class="mr-1" label x-small color="secondary" dark style="padding: 0 10px !important"
                v-if="cliente.viernes === '1'">
                VI
              </v-chip>
            </template>
          </div>
          <v-chip v-if="cliente.visita && dist_status !== null && dist_status.text !== ''" small color="grey lighten-4"
            class="py-3" label>
            <v-icon :color="dist_status.color" size="16" class="mt-n1 mr-1">mdi-map-marker</v-icon>
            <span class="font-weight-bold display" :class="`${dist_status.color}--text`">
              {{ dist_status.text }}
            </span>
          </v-chip>
        </div>
        <div v-if="cliente.visita" class="pt-3 d-flex flex-wrap justify-space-between">
          <div>
            <span v-if="cliente.visita.hora_inicio && cliente.visita.hora_inicio !== null" class="blue-grey--text mr-1">
              <v-icon color="icono" size="16" class="mt-n1">mdi-clock-outline</v-icon>
              {{fecha + ' ' +cliente.visita.hora_inicio | hora }}
            </span>
            <span v-if="cliente.visita.hora_fin && cliente.visita.hora_fin !== null" class="blue-grey--text">
              <v-icon color="red" size="16" class="mt-n1">mdi-clock-outline</v-icon>
              {{fecha + ' ' + cliente.visita.hora_fin | hora }}
            </span>
          </div>
          <span v-if="visita_duracion && visita_duracion !== '0'" class="primary--text font-weight-medium">
            <v-icon color="primary" size="16" class="mt-n1">mdi-alarm-check</v-icon>
            {{ visita_duracion }}
          </span>
        </div>
        <div v-if="cliente.visita" class="mt-3 d-flex align-center justify-space-between">
          <div class="d-flex flex-row align-center">
            <v-icon color="yellow darken-3" size="20" left style="margin-top: -2px">mdi-star</v-icon>
            <span class="font-weight-medium black--text" v-text="cliente.visita.tipo_visita" />
          </div>
        </div>
        <div class="pt-2" v-if="cliente.visita && cliente.visita.cod_pedido">
          <v-icon size="16">mdi-comment-text-multiple-outline
          </v-icon>
          <span class="text-caption px-2">
            {{ cliente.visita.cod_pedido || 'Sin comentario' }}
          </span>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showDetails" max-width="70%" scrollable>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="blue-grey" dense flat>
            <v-toolbar-title class="white--text subtitle-text">
              <v-icon color="white" size="20" class="mt-n1 mr-1">mdi-text-box</v-icon>
              <span class="font-weight-regular">
                Detalles de Visita
              </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showDetails = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <detalles-visita :visita="detailsData" class="ma-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import moment from 'moment'
import { gmapApi } from 'gmap-vue'
export default {
  name: 'VisitaClientes',
  components:{
    DetallesVisita: () => import(
      /* webpackChunkName: "detalles-visita" */
      './DetallesVisita.vue'
    ),
   },
  props:{
    cliente:{
      type: Object,
      default: () => ({})
    },
    fecha:{
      type: String,
      default: moment().format("YYYY-MM-DD")
    }
  },
  data() {
    return {
      dist_metros: '',
      dist_status: {
        text: '',
        color: '',
      },
      showDetails: false,
    }
  },
  mounted () {
    this.$gmapApiPromiseLazy().then(() => {
      if(this.cliente.lat !== null && this.cliente.lng !== null && this.cliente.visita) {
        var visita = new this.googleApi.maps.LatLng(parseFloat(this.cliente.visita.lat.trim()), parseFloat(this.cliente.visita.lng.trim()));
        var cliente = new this.googleApi.maps.LatLng(parseFloat(this.cliente?.lat?.trim()), parseFloat(this.cliente?.lng?.trim()));
        this.dist_metros = this.googleApi.maps.geometry.spherical.computeDistanceBetween(visita, cliente).toFixed(2);

        this.dist_status = this.dist_metros < 100
        ? {text: 'D.R', color: 'icono' }
        : this.dist_metros > 100 && this.dist_metros < 300
        ? { text: 'S.R', color: 'warning' }
        : this.dist_metros > 300 ? {text: 'F.L', color: 'red' }
        : null
      }
    })
  },
  watch: {
    cliente (val) {
      this.$gmapApiPromiseLazy().then(() => {
        if(val.lat !== null && val.lng !== null && val.visita) {
          var visita = new this.googleApi.maps.LatLng(parseFloat(val.visita.lat.trim()), parseFloat(val.visita.lng.trim()));
          var cliente = new this.googleApi.maps.LatLng(parseFloat(val.lat.trim()), parseFloat(val.lng.trim()));

          this.dist_metros = this.googleApi.maps.geometry.spherical.computeDistanceBetween(visita, cliente).toFixed(2);

          this.dist_status = this.dist_metros < 100
          ? {text: 'D.R', color: 'icono' }
          : this.dist_metros > 100 && this.dist_metros < 300
          ? { text: 'S.R', color: 'warning' }
          : this.dist_metros > 300 ? {text: 'F.L', color: 'red' }
          : null
        }
      })
    }
  },
  filters: {
    hora(time) {
       return time != "null" ? moment(time).format('hh:mm a') : '';
    },
    truncFrecuencia(val) {
      return val.substr(0,1);
    }
  },
  computed: {
    googleApi: gmapApi,
    estadoVisita () {
      let now = moment().format("YYYY-MM-DD");

      if(moment(this.fecha).isSameOrAfter(now)) {
        return this.cliente.visita
          ? { color: 'success-alert', text: 'Visitado' }
          : { color: 'warning-alert', text: 'P/V' }

      }
      else if(moment(this.fecha).isBefore(now)) {
        return this.cliente.visita
          ? { color: 'success-alert', text: 'Visitado' }
          : { color: 'purple-alert', text: 'No Visitado' }
      }
      return { color: '', text: '' }
    },
    futuro () {
      let now = moment().format("YYYY-MM-DD");
      return moment(this.fecha).isSameOrAfter(now);
    },
    visita_duracion () {
      if (this.cliente.visita.hora_inicio !== null && this.cliente.visita.hora_fin !== null) {
        const inicio = moment(`${this.fecha} ${this.cliente?.visita?.hora_inicio}`);
        const fin = moment(`${this.fecha} ${this.cliente?.visita?.hora_fin}`);
        const duracion = fin.diff(inicio)

        const hours = Math.floor(moment.duration(duracion).asHours()).toString()

        return `${ hours.length < 2 ? hours.padStart(2, '0') : hours }:${ moment.utc(duracion).format("mm:ss") }`
      }
      return null
    },
    detailsData(){
      return {
        ...this.cliente?.visita,
        pedido: this.cliente?.pedido,
        lat_cliente: this.cliente?.lat,
        lng_cliente: this.cliente?.lng,
        cli_des: this.cliente?.cli_des
      }
    }
  },
  methods: {
    viewDetails(){
      console.log(this.cliente.visita);
      if(this.cliente.visita)
        this.showDetails = true
    }
  }
}
</script>
<style>
.point-sale-remove{
  position: absolute !important;
  top: -15%;
  left: 42%;
  width: 2px;
  height: 130%;
  opacity: .8;
  background-color: #cfd8dc !important;
  transform: rotate(-40deg) translateX(50%);
}

.details-visit {
  cursor: pointer;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  overflow: hidden;
}

.details-visit:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  top: 0;
  background: #003b49 !important;
  width: 4px;
  -webkit-transform: translateX(-4px);
  transform: translateX(-4px);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.details-visit:hover:before, .details-visit:focus:before, .details-visit:active:before {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}


</style>
