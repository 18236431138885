<template>
  <v-list>
    <v-list-item v-for="(cli,i) in clientes" :key="'detailsvis-'+i">
      <v-list-item-content>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <span class="titulo-visita" v-text="cli.cli_des+' ('+cli.co_cli+')'"></span>
          </v-col>
          <v-col cols="4" class="d-flex align-center justify-center" v-if="$vuetify.breakpoint.mdAndUp">
            <v-chip
              class="mx-2"
              label
              small
              color="icono"
              dark
              v-if="cli.lunes === '1'"
            >
              LU
            </v-chip>
            <v-chip
              class="mx-2"
              label
              small
              color="purple"
              dark
               v-if="cli.martes === '1'"
            >
              MA
            </v-chip>
            <v-chip
              class="mx-2"
              label
              small
              color="info"
              dark
               v-if="cli.miercoles === '1'"
            >
              MI
            </v-chip>
            <v-chip
              class="mx-2"
              label
              small
              color="orange"
              dark
               v-if="cli.jueves === '1'"
            >
              JU
            </v-chip>
            <v-chip
              class="mx-2"
              label
              small
              color="secondary"
              dark
               v-if="cli.viernes === '1'"
            >
              VI
            </v-chip>
          </v-col>
          <v-col cols="2" class="d-flex align-center justify-center" v-if="$vuetify.breakpoint.mdAndUp">
            <v-icon small left color="blue-grey">mdi-replay</v-icon>
            <span class="blue-grey--text text--darken-3" v-text="cli.frecuencia"></span>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  name: 'VisitaClientes',
  props:{
    clientes:{
      type: Array,
      default: () => ([])
    }
  }
}
</script>